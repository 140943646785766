import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay } from "swiper";
import "swiper/swiper.scss";

type Props = {
  children?: React.ReactNode;
  slides: { src: string; alt: string }[];
};

const SwipperSlideshow: React.FC<Props> = ({ slides }) => {
  SwiperCore.use([Autoplay]);
  return (
    <Swiper
      // autoHeight
      loop
      speed={1000}
      slidesPerView="auto"
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      className="about__slides--swipper"
    >
      {slides.map(({ src: image, alt }: any, i) => (
        <SwiperSlide
          key={`${alt}-${i}`}
          className="about__slides--swipper--slide"
        >
          {/* Sadly, does not work on build */}
          {/* <PreviewCompatibleImage imageInfo={{ image, alt }} /> */}
          {/* Sadly, had to create a custom img with image-sharp */}
          <img
            src={
              typeof image === "string"
                ? image
                : image.childImageSharp.fluid.srcWebp
            }
            srcSet={
              typeof image === "string"
                ? ""
                : image.childImageSharp.fluid.srcSetWebp
            }
            alt={alt}
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwipperSlideshow;
