export const fixPath = (path: string) => {
  return path?.endsWith("/") ? path.slice(0, -1) : path;
};

export const getPageSession = (): string[] => {
  if (typeof window === "undefined") return;

  const page = window.sessionStorage.getItem("page");
  const pages = page ? JSON.parse(page) : [];

  return pages;
};

export const isVisited = (visitedPages: string[] = [], path: string) => {
  return visitedPages?.some((p) => fixPath(p) === fixPath(path));
};

const MASTER_PASSWORDS = ["Shmerkin!master"];

export const validatePassword = (
  originalPassword: string | undefined[],
  password: string
) => {
  return [...MASTER_PASSWORDS, originalPassword]
    .filter(Boolean)
    .some((p) => p === password);
};

export const addPageToSession = (path: string) => {
  if (typeof window === "undefined") return;
  else if (isVisited(getPageSession(), path)) return;

  const page = window.sessionStorage.getItem("page");
  const pages = page ? JSON.parse(page) : [];

  pages.push(fixPath(path));
  window.sessionStorage.setItem("page", JSON.stringify(pages));
};
