import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Artist } from "../../../models";
import { PreviewCompatibleImage } from "../../index";
import { animCoverProps__logo, isMobile } from "../../../helpers";
import { SocialIcons } from "../../Social";
import { navigate } from "gatsby-link";

type ArtistCardProps = {
  artist: Artist;
  slug: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const ArtistCard = (props: ArtistCardProps) => {
  const { stageName, recapLine, social, featuredimage } = props.artist;
  return (
    <div
      className={`artists__item ${props.className ?? ""}`}
      onClick={(e) => (isMobile ? e.preventDefault() : navigate(props.slug))}
    >
      <div className="artists__item__wrapper">
        <div className="artists__item__preview">
          <div className="artists__item__preview--texts">
            <AniLink cover to={props.slug} {...animCoverProps__logo}>
              <h1>{stageName}</h1>
            </AniLink>
            <div className="artists__item__preview--hover">
              <h3>{recapLine}</h3>
            </div>
          </div>
          <SocialIcons
            data={social}
            className="artists__item__preview--social"
          />
        </div>
      </div>
      <div className="artists__item__thumbnail">
        <PreviewCompatibleImage
          imageInfo={{
            image: featuredimage,
            alt: stageName,
            style: {
              height: "450px",
            },
          }}
        />
      </div>
    </div>
  );
};

export default ArtistCard;
