import React from "react";
import { Helmet } from "react-helmet";
import { Layout, SectionWrapper } from ".";

type Props = {
  title: string;
  children: React.ReactNode;
  noLayout?: boolean;
  wrapperClasses?: string;
};

const SimpleRollPage: React.FC<Props> = ({
  title,
  children,
  noLayout,
  wrapperClasses,
}: Props) => {
  const InnerLayout = noLayout ? React.Fragment : Layout;
  return (
    <React.Fragment>
      <Helmet titleTemplate={`${title} | REAL CVLTVRE`}>
        <title>{`${title} | REAL CVLTVRE`}</title>
        <meta name="description" content={`${title} | REAL CVLTVRE`} />
      </Helmet>
      <InnerLayout>
        <SectionWrapper
          title={title}
          className={["container-width-l simple-page", wrapperClasses]
            .filter(Boolean)
            .join(" ")}
        >
          {children}
        </SectionWrapper>
      </InnerLayout>
    </React.Fragment>
  );
};

export default SimpleRollPage;
