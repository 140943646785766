import React from "react";
import { isMobile } from "../helpers";
import Tilt from "react-parallax-tilt";

export const DecideTilt: React.FC<any> = ({
  children,
  className,
  ...tiltProps
}) => {
  return isMobile ? (
    <div className={className}>{children}</div>
  ) : (
    <Tilt className={className} {...tiltProps}>
      {children}
    </Tilt>
  );
};
