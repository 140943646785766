import React from "react";
import { GlobalState } from "../../store";

type Props = {
  className?: string;
  onClick?: Function;
};

/**
 * The button listenes to state,
 * To get the menu button to open you must dispatch
 * @example   const dispatch = React.useContext(GlobalDispatch);
 *  dispatch(AppActions.toggleMenu())
 */
const MenuButton: React.FC<Props> = (props: Props) => {
  const { className, onClick } = props;
  const state = React.useContext(GlobalState);
  // prettier-ignore
  return (
    <div
      className={`menu-toggle cursor-trigger${
        state.menuOpen ? " menu-toggeled" : ""
      } ${className ?? ""}`}
      onClick={() => onClick && onClick()}
    >
      {Array.from(Array(4)).map((_, i) => (
        <span key={i} />
      ))}
    </div>
  );
};

export default MenuButton;
