import React, { forwardRef } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { animCoverProps__logo } from "../../helpers";
import { ArrowRight } from "../SVG";
import "../../styles/ui.scss";

type Props = {
  title?: string;
  link?: string;
  children?: React.ReactNode;
  className?: string;
  id?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const SectionWrapper = forwardRef((props: Props, ref: any) => {
  const { title, children, link, className, id } = props;
  return (
    <section
      ref={ref}
      style={props.style}
      className={`section ${className ?? ""}`}
      id={id ? id : title ? title?.toLowerCase() : ""}
    >
      {title && (
        <header className="section__header">
          <div className="section__header__container">
            <h1 className="section__header__container--title">{title}</h1>
            {link && (
              <div className="section__header__container--action">
                <AniLink
                  cover
                  to={link}
                  className="arrow-right"
                  {...animCoverProps__logo}
                >
                  See all
                  <ArrowRight
                    height="16"
                    width="16"
                    stroke={"#fff"}
                    style={{ transform: "rotate(-90deg)" }}
                  />
                </AniLink>
              </div>
            )}
          </div>
          <div className="gradient-line" />
        </header>
      )}
      {children && <div className="section__content">{children}</div>}
    </section>
  );
});

export default SectionWrapper;
