import { gsap, Back } from "gsap";
import {
  map,
  lerp,
  getMousePos,
  calcWinsize,
  getRandomNumber,
  isMobile,
} from "../utils";

class GridItem {
  public frames: number[] = [];
  private winsize: { width: number; height: number } = calcWinsize();
  private mousepos = { x: this.winsize.width / 2, y: this.winsize.height / 2 };

  constructor(el: Element) {
    this.move(el);
    // Calculate the viewport size
    window.addEventListener("resize", () => (this.winsize = calcWinsize()));
    // Track the mouse position
    window.addEventListener(
      "mousemove",
      (ev) => (this.mousepos = getMousePos(ev))
    );
  }

  // Move the items when moving the cursor
  move(el: Element) {
    // amounts to move in each axis
    let translationVals = { tx: 0, ty: 0 };
    // get random start and end movement boundaries
    const xstart = getRandomNumber(50, 200);
    const ystart = getRandomNumber(20, 50);
    // infinite loop
    const render = () => {
      // Calculate the amount to move.
      // Using linear interpolation to smooth things out.
      // Translation values will be in the range of [-start, start] for a cursor movement from 0 to the window's width/height
      translationVals.tx = lerp(
        translationVals.tx,
        map(this.mousepos.x, 0, this.winsize.width, -xstart, xstart),
        0.07
      );
      translationVals.ty = lerp(
        translationVals.ty,
        map(this.mousepos.y, 0, this.winsize.height, -ystart, ystart),
        0.07
      );

      gsap.set(el, { x: translationVals.tx, y: translationVals.ty });
      this.frames.push(requestAnimationFrame(render));
    };
    this.frames.push(requestAnimationFrame(render));
  }

  killAllRequestAnimationFrames() {
    if (this.frames.length > 1)
      for (const id of this.frames) cancelAnimationFrame(id);
  }
}

export default class GripTapeGrid {
  constructor(
    public items: Element[] | string,
    public gridItems: GridItem[] = []
  ) {
    // Slides all elements from bottom, be sure to add overflow: hidden
    this.showItems();

    if (!isMobile) this.buildTapeGrid();
  }
  private buildTapeGrid() {
    // If given param a querystring
    if (typeof this.items === "string")
      this.items = Array.from(document.querySelectorAll(this.items));
    // Create mooving grid instance for each item
    [...this.items].forEach((item: Element) =>
      this.gridItems.push(new GridItem(item))
    );
  }
  showItems() {
    gsap.timeline().from(
      this.items,
      {
        yPercent: 120,
        duration: 1,
        delay: 0.3,
        ease: Back.easeInOut,
        stagger: { amount: 0.25, from: "end" },
      },
      ">-.3"
    );
  }
  public kill() {
    for (const el of this.gridItems) el.killAllRequestAnimationFrames();
  }
}
