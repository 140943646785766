/**
 * @param state - useState
 * @param setState - useState dispatch
 * @param offsetOff - Consider using if under 3 items
 * @example
 * const initialNavItems: any = {
 *   header: { id: "header", text: title, ref: useRef<HTMLDivElement>(null) },
 *   main: { id: "main", text: "Content", ref: useRef<HTMLDivElement>(null) },
 * };
 * const [scrollNavConfig, setScrollNavConfig] = useState(initialNavItems);
 * const scrollEvent = (): void => {
 *   progressOnScroll(scrollNavConfig, setScrollNavConfig, true);
 * };
 */
export const progressOnScroll = (
  state: any,
  setState: React.Dispatch<any>,
  offsetOff: boolean = false
) => {
  if (!state) return;
  const sectionsProg = [];
  Object.keys(state).forEach((key, index) => {
    const { scrollTop } = document.documentElement;
    const el = state[key].ref.current as HTMLDivElement;
    if (!el) {
      console.log("no el", key);
      return;
    }
    const offset = offsetOff
      ? 260
      : index === 0
      ? el.offsetHeight / 2
      : el.offsetWidth >= 1000
      ? el.offsetWidth / 3
      : el.offsetWidth / 2 + 800 - 400;

    const elBegginingPosition = el.offsetTop - el.scrollTop + el.clientTop;
    const elEndingPosition = elBegginingPosition + el.offsetHeight;

    const divProgFromTop =
      (scrollTop - elBegginingPosition + offset) /
      ((el.offsetHeight + offset) / 3 + (index === 0 ? 0 : 200));
    const passed = scrollTop >= elEndingPosition;
    const active =
      scrollTop >= elBegginingPosition - offset &&
      scrollTop <= elEndingPosition;

    if (divProgFromTop >= 0) {
      sectionsProg.push({
        key,
        divProg: divProgFromTop,
        elBegginingPosition,
        elEndingPosition,
        passed,
        active,
      });
    }
  });
  setState(
    Object.keys(state).map((key) => {
      const nav = state[key];
      const prog = sectionsProg.find((item) => item.key === key);
      if (!prog) return nav;
      const { divProg, passed, active } = prog;
      if ((divProg >= 0 && divProg <= 1) || (divProg >= 1 && divProg <= 2)) {
        return {
          ...nav,
          progress: {
            in: divProg >= 0 && divProg <= 1 ? divProg : 0,
            out: divProg >= 1 && divProg <= 2 ? divProg - 1 : 0,
          },
          passed,
          active,
        };
      }
      return { ...nav, passed };
    })
  );
};

export const setOn = (location: string, state: any, setState: any) => {
  const newNav: any = { ...state };
  newNav[location] = {
    ...newNav[location],
    progress: {
      in: 1,
      out: 0,
    },
    active: true,
  };
  setState(newNav);
};
