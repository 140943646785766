import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Social } from "../../models";
import { isMobile } from "../../helpers";
import gsap from "gsap";
import Icon from "../Icon";
import { useHideOnScrolled } from "../../hooks";
import "./Social.scss";

type Props = {
  data?: Social;
} & React.HTMLAttributes<HTMLDivElement>;

const SocialBar: React.FC<Props> = ({ data }: Props) => {
  const hidden = useHideOnScrolled();
  const [isReady, setIsReady] = React.useState(false);
  const [readyIcons, setReadyIcons] = React.useState([]);

  const show = () =>
    gsap.fromTo(
      ".social-bar",
      { xPercent: isMobile ? 150 : -150 },
      { xPercent: 0 }
    );

  React.useEffect(() => {
    if (readyIcons.length === data?.links?.length && !isReady) setIsReady(true);
    if (isReady) !hidden ? show() : show().reverse(0);
  }, [isReady, readyIcons, hidden]);

  return data.links.length ? (
    <ul
      className="social-bar"
      style={{ visibility: !isReady ? "hidden" : "visible" }}
    >
      {data.links.map(({ type, url }) => (
        <li key={`${type}-socialbar`}>
          <a href={url} target="_blank">
            <Icon
              name={type}
              size={35}
              onReady={() => setReadyIcons((icons) => [...icons, type])}
            />
          </a>
        </li>
      ))}
    </ul>
  ) : null;
};

export default React.memo(({ data }: Props) => (
  <StaticQuery
    query={graphql`
      query SocialBarQuary {
        markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
          frontmatter {
            social {
              links {
                type
                url
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <SocialBar data={data?.markdownRemark?.frontmatter.social} />
    )}
  />
));
