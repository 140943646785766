import React, { forwardRef } from "react";
import { RealCvltvreLogo } from "../../SVG";
import { WebGLSlideShow, DecideTilt } from "../../";
import SwipperSlideshow from "./SwipperSlideshow";
import { isMobile } from "../../../helpers";

import "swiper/swiper.scss";
import "./About.scss";
interface AboutData {
  title: string;
  featuredimage: { picture: any; alt: string };
  bio: string;
  slides: { src: string; alt: string }[];
}
type Props = {
  data: AboutData;
  children?: React.ReactNode;
  className?: string;
  isPreview?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;
const AboutSection = (props: Props, ref: any) => {
  const { data: about } = props;
  return (
    <section
      className={`section cross about ${props.className}`}
      ref={ref}
      id="about"
    >
      <DecideTilt
        perspective={1000}
        tiltMaxAngleX={4}
        tiltMaxAngleY={4}
        tiltReverse={true}
        className="about__slides preserve"
      >
        {isMobile || props.isPreview ? (
          <React.Fragment>
            <SwipperSlideshow slides={about.slides} />
            <div className="dummy"></div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="dummy"></div>
            {!isMobile && (
              <div className="about__slides--displaceable cursor-trigger">
                <WebGLSlideShow images={about.slides} />
              </div>
            )}
          </React.Fragment>
        )}
        <div className="about__logo">
          <RealCvltvreLogo className="about__logo--svg" />
        </div>
      </DecideTilt>
      <div className="about__details">
        <div className="about__details--title">
          <h1>{about.title}</h1>
        </div>
        <p>{about.bio}</p>
      </div>
    </section>
  );
};

export default forwardRef(AboutSection);
