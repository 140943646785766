import React from "react";
import { MenuButton } from "../index";
import { AppActions, GlobalDispatch } from "../../store";
import { animCoverProps__logo, isMobile } from "../../helpers";
import { Cart } from "../SVG";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import "./ProgressNavbar.scss";

export interface ProgressNavbarItem {
  id: string;
  text?: string;
  progress?: {
    in: number;
    out: number;
  };
  passed?: boolean;
  active?: boolean;
}

type Props = {
  data?: ProgressNavbarItem[];
  single?: boolean;
  children?: React.ReactNode;
};

const ProgressNavbar: React.FC<Props> = (props: Props) => {
  const { single, data, children } = props;

  const dispatch = React.useContext(GlobalDispatch);
  const [scrolled, setScrolled] = React.useState(0);
  const movingRef = React.useRef(null);

  React.useEffect(() => {
    if (isMobile && data) setXScroll();
  }, [data]);

  const setXScroll = () => {
    const {
      body: { scrollTop: bodyScrollTop },
      documentElement: { scrollHeight, clientHeight, scrollTop },
    } = document;

    setScrolled(
      ((bodyScrollTop || scrollTop) / (scrollHeight - clientHeight)) * 100
    );
    // Scroll inside Menu bar if mobile
    (movingRef.current as Element).scrollLeft = scrolled * 3;
  };

  const animatedBars =
    data &&
    Object.keys(data).map((key, i) => {
      const item = data[key];
      return (
        <a
          href={`#${item.id?.toLowerCase()}`}
          className={
            (item.passed || item.active) &&
            `${item.passed ? "passed " : ""}${item.active ? "active " : ""}`
          }
          key={i}
        >
          <div
            className={"progress"}
            style={{
              transform: item.progress?.in
                ? `scaleX(${item.progress.in}) translateZ(0px)`
                : `none`,
            }}
          />
          <div
            className={"progress out"}
            style={{
              transform: item.progress?.out
                ? `scaleX(${item.progress.out}) translateZ(0px)`
                : `none`,
              background: item.progress?.out ? `white` : ``,
            }}
          />
          {item.text ? item.text : item.id}
        </a>
      );
    });

  return (
    <nav>
      <div
        className="menu-button"
        onClick={() => dispatch(AppActions.toggleMenu())}
      >
        <MenuButton />
      </div>
      {data && (
        <div
          className={`amazing-menu ${single ? "single" : ""}`}
          ref={movingRef}
        >
          {!single && isMobile ? <div className="dummy" /> : ""}
          {data && animatedBars}
        </div>
      )}
      {children && <>{children}</>}
      <div className="suffix-button">
        <AniLink cover to={"/mart"} {...animCoverProps__logo}>
          <Cart className="white-svg" height={40} width={40} />
        </AniLink>
      </div>
    </nav>
  );
};

export default React.memo(ProgressNavbar);
