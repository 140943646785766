import React from "react";
import gsap, { Power4 } from "gsap";
import { SEO, Footer, FixedMenuNav } from "./index";
import { closeMenuOnLoad } from "../hooks";
import "../styles/_ignore.scss";
import "./styles.scss";
import { isMobile, useCursor } from "../helpers";

type Props = {
  children: React.ReactNode;
  ignoreNav?: boolean;
  ignoreFooter?: boolean;
  className?: string;
  ignoreAnimations?: boolean;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Layout: React.FC<Props> = ({
  children,
  ignoreNav,
  ignoreFooter,
  ignoreAnimations,
}: Props) => {
  useCursor(".cursor");
  closeMenuOnLoad();

  const pageUpAnimation = () =>
    gsap
      .timeline()
      .fromTo(
        ".gradient-line",
        {
          scaleX: 0,
          transformOrigin: "0% 50%",
          backgroundSize: "250%",
          opacity: 0.5,
        },
        {
          opacity: 1,
          scaleX: 1,
          transformOrigin: "0% 50%",
          backgroundSize: "100%",
          duration: 1,
          ease: Power4.easeInOut,
        }
      )
      .from(
        ".section > header > .section__header__container",
        {
          opacity: 0,
          yPercent: 200,
          duration: 1,
          ease: Power4.easeInOut,
          stagger: { amount: 0.3, from: "start" },
        },
        "<"
      )
      .from(
        ".gsap-list > div, .gsap-list > article, ul > *",
        {
          opacity: 0,
          duration: 1.5,
          delay: 0.3,
          ease: Power4.easeInOut,
          stagger: {
            each: 0.1,
            from: "start",
            grid: "auto",
            ease: Power4.easeIn,
          },
        },
        "<"
      );

  React.useEffect(() => {
    if (!ignoreAnimations) pageUpAnimation();

    if (typeof window !== "undefined") {
      require("smooth-scroll")('a[href*="#"]', {
        speed: 2000,
        speedAsDuration: true,
        easing: "easeInOutQuint",
      });
    }
  }, []);

  return (
    <div className="real-cvltvre__layout" style={{ position: "relative" }}>
      {/* Helmet */}
      <SEO />
      {/* Nav */}
      {!ignoreNav && <FixedMenuNav />}
      {/* Next line is just a placeholder for the navbar */}
      {!ignoreNav && isMobile && <div style={{ height: "50px" }} />}
      {/* Page */}
      {children}
      {/* Footer */}
      {!ignoreFooter && <Footer />}
      {/* Autograph */}
      <div
        className="footer__autograph"
        style={{ height: "50px", display: "grid", placeContent: "center" }}
      >
        <a
          href="https://dananz.com"
          target="_blank"
          style={{ margin: "0 0 2em" }}
        >
          Designed & built by{" "}
          <strong className="line-through-link">Dananz</strong>
        </a>
      </div>
      <svg className="cursor" width="25" height="25" viewBox="0 0 25 25">
        <circle className="cursor__inner" cx="12.5" cy="12.5" r="6.25" />
      </svg>
    </div>
  );
};

export default React.memo(Layout);
