import { remark } from 'remark'
import remarkRehype from 'remark-rehype'
import rehypeStringify from 'rehype-stringify'
import rehypeRaw from 'rehype-raw'


const isNullOrUndefined = (v) => v === null || v === undefined;


const rcEncodeUri = (data) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");

const getNavigator = () => {
  try {
    return navigator?.userAgent;
  } catch {
    return undefined;
  }
};

const isMobile = /Mobi/.test(getNavigator());

const isCookieEnabled = (cookie: string) => {
  try {
    return document.cookie.includes(`${cookie}=true`) || undefined;
  } catch {
    return undefined;
  }
};

// Returns cuurrent path without domain
const getPaths = () => {
  const url = typeof window !== "undefined" ? window.location.href : "";
  let split = url.endsWith("/")
    ? url.substring(0, url.length - 1).split("/")
    : url.split("/");
  split = split.splice(3, split.length - 1);
  return split;
};

const isCurrentPage = (find: string[]) => {
  const paths = getPaths();
  return (
    paths.length === 0 ||
    find
      .map((x) => x.replace("/", "").toLowerCase())
      .includes(paths[paths.length - 1])
  );
};

const toHTML = value => remark()
  .use(remarkRehype, { allowDangerousHtml: true, })
  .use(rehypeStringify)
  .use(rehypeRaw)
  .processSync(value)
  .toString()

const mapRemarkToPage = <T = any>(edges: any): T => {
  if (!edges)
    throw new Error(
      `Error in mapRemarkToPage: edges is not available, { edges: ${JSON.stringify(
        edges
      )} }`
    );
  const isArray = Array.isArray(edges);
  if (!isArray) edges = [edges];
  const mapped = edges.map((n) => {
    if (isArray || n?.node) n = n?.node;
    const fixedFrontmatter = Object.fromEntries(Object.entries((n?.frontmatter || n)).map(([k, val]) => {
      const fixedVal = k.endsWith('Html') ? toHTML(val) : val;
      return [k, fixedVal]
    }));

    return {
      id: n?.id ?? null,
      slug: n?.fields?.slug ?? null,
      ...fixedFrontmatter,
    };
  });
  return !isArray ? mapped[0] : mapped;
};



//////////////////////////////////////////////////// ANIMATIONS

const getRandomNumber = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1) + min);

// Map number x from range [a, b] to [c, d]
const map = (x: number, a: number, b: number, c: number, d: number): number =>
  ((x - a) * (d - c)) / (b - a) + c;

// Linear interpolation
const lerp = (a: number, b: number, n: number): number => (1 - n) * a + n * b;

const calcWinsize = (): { width: number; height: number } => {
  try {
    return { width: window.innerWidth, height: window.innerHeight };
  } catch {
    return undefined;
  }
};

// Gets the mouse position
const getMousePos = (e: MouseEvent): { x: number; y: number } => {
  let posX = 0;
  let posY = 0;

  posX = e.clientX + document.body.scrollLeft;
  posY = e.clientY + document.body.scrollTop;

  return { x: posX, y: posY };
};

export {
  rcEncodeUri,
  isMobile,
  isCookieEnabled,
  isCurrentPage,
  getPaths,
  map,
  lerp,
  calcWinsize,
  getRandomNumber,
  getMousePos,
  mapRemarkToPage,
  toHTML,
  isNullOrUndefined,
};
