import React from "react";
import "lazysizes";
import { GlobalStoreProvider } from "./src/store";

export const wrapRootElement = ({ element }) => {
  return <GlobalStoreProvider>{element}</GlobalStoreProvider>;
};

export const onInitialClientRender = () => {
  window.___REALCVLTVRE_INITIAL_RENDER_COMPLETE = true;
};
