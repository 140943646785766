import React from "react";
import { rcEncodeUri } from "../../helpers/utils";
import { TimelineLite } from "gsap";
import "./Newsletter.scss";

type Props = {
  className?: string;
  style?: any;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
const Newsletter: React.FC<Props> = (props: Props) => {
  const [email, setEmail] = React.useState(null);
  const [completed, setCompleted] = React.useState(false);
  const [formError, setFormError] = React.useState(false);

  // ---------------------- // GSAP
  const tl = new TimelineLite();
  const gsapEl = { rollup: React.useRef(null), thankU: React.useRef(null) };
  const revealThankU = () => {
    const { rollup, thankU } = gsapEl;
    tl.to(rollup.current, { y: "0", duration: 0.66 })
      .to(thankU.current, { y: "-100%", duration: 0.66 }, ">1")
      .to(rollup.current, { y: "-100%", duration: 0.66 }, "<");
    tl.play();
  };

  React.useEffect(() => {
    if (completed) revealThankU();
    return () => {
      tl.clear();
    };
  }, [completed]);

  // ---------------------- // FORM
  const handleChange = (e) => setEmail({ [e.target.name]: e.target.value });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) return;
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: rcEncodeUri({
        "form-name": form.getAttribute("name"),
        ...email,
      }),
    })
      .then(({ ok }) => !ok && setFormError(true))
      .finally(() => setCompleted(true));
  };

  return (
    <section
      className={`newsletter ${props.className ?? ""}`}
      style={props.style}
    >
      <form
        className="newsletter__form"
        name="newsletter"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="newsletter" />
        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>
        <input
          id={"newsletter-email"}
          type={"email"}
          name={"email"}
          required={true}
          className="input"
          autoComplete={"email"}
          onChange={handleChange}
          maxLength={100}
          placeholder="Type your email"
        />
        <button type="submit" data-text="Subscribe">
          <span>Subscribe</span>
        </button>
      </form>
      {completed && (
        <div className="newsletter__thank-u">
          <div className="newsletter--rollup" ref={gsapEl.rollup}></div>
          <div className="newsletter--confirmation" ref={gsapEl.thankU}>
            <h1>
              {formError
                ? "Oops, try again later"
                : "Thank u for taking a part"}
            </h1>
          </div>
        </div>
      )}
    </section>
  );
};

export default Newsletter;
