import React from "react";
import PropTypes from "prop-types";
import Tilt from "react-parallax-tilt";
import { Social } from "../../../models";
import { isMobile } from "../../../helpers";
import { RealCvltvreIcon } from "../../SVG";
import { graphql, Link, StaticQuery } from "gatsby";
import { Contact, SocialIcons, GradientLine, Newsletter } from "../../index";
import "./Footer.scss";

type FooterProps = {
  data?: {
    outro: string;
    social: Social;
    contactRequirements: { requirements: any };
    details: string[];
  };
  className?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Footer = ({ data, className }: FooterProps) => {
  return (
    <footer className={`footer ${className ?? ""}`} id="footer">
      <section className="footer__wrapper">
        <div className="footer__wrapper__contact">
          <Contact
            requirements={data.contactRequirements?.requirements}
            className="footer__wrapper__contact--form"
          />
        </div>
        <div className="footer__wrapper__outro">
          <div className="footer__wrapper__outro--content">
            <div className="footer__wrapper--description">
              {isMobile ? (
                <div className="footer__wrapper--description--tilt-line">
                  <h3>{data.outro}</h3>
                </div>
              ) : (
                <Tilt
                  perspective={500}
                  tiltMaxAngleX={4}
                  tiltMaxAngleY={4}
                  tiltReverse={true}
                  className="footer__wrapper--description--tilt-line cursor-trigger"
                >
                  <h3>{data.outro}</h3>
                </Tilt>
              )}
              {data.details && data.details.length && (
                <address>
                  {data.details.map((item) => (
                    <li key={item + `item`}>
                      <span className="cursor-trigger">{item}</span>
                    </li>
                  ))}
                </address>
              )}
            </div>
            <div className="footer__wrapper__actions">
              <RealCvltvreIcon className="footer__wrapper__actions--logo" />
              <SocialIcons
                data={data.social}
                className="footer__wrapper__actions--social"
              />
            </div>
          </div>
        </div>
      </section>
      <GradientLine className="footer__wrapper--hr" />
      <Newsletter style={{ margin: "3rem auto", padding: "0 1rem" }} />
      <div className="footer__privacy">
        <Link to={"/privacy"}>Privacy</Link>
        <Link to={"/terms"}>Terms</Link>
      </div>
    </footer>
  );
};

export const FooterTemplate = ({ data }) => <Footer data={data} />;

Footer.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        outro: PropTypes.string,
        details: PropTypes.array,
        social: PropTypes.shape({
          links: PropTypes.shape({
            type: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
        contactRequirements: PropTypes.shape({
          requirements: PropTypes.array,
        }),
      }),
    }),
  }),
};

export default ({ data, className }: FooterProps) => (
  <StaticQuery
    query={graphql`
      query FooterData {
        markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
          frontmatter {
            outro
            details
            social {
              links {
                type
                url
              }
            }
            contactRequirements {
              requirements {
                reason
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Footer data={data?.markdownRemark?.frontmatter} className={className} />
    )}
  />
);
