import React from "react";
import PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import ArtistCard from "./ArtistCard";
import "./Artists.scss";

const ArtistsRoll = ({ data }) => {
  const { edges: artists } = data.allMarkdownRemark;

  return (
    <div className="artists gsap-list">
      {artists &&
        artists.map(({ node: artist }) => (
          <ArtistCard
            artist={artist.frontmatter}
            slug={artist.fields.slug}
            key={artist.id}
          />
        ))}
    </div>
  );
};

ArtistsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query ArtistsRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "artist-page" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                stageName
                recapLine
                templateKey
                social {
                  links {
                    type
                    url
                  }
                }
                featuredimage {
                  childImageSharp {
                    fluid(
                      maxWidth: 450
                      quality: 100
                      srcSetBreakpoints: [250, 450]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <ArtistsRoll data={data} />}
  />
);
