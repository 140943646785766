import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const PreviewCompatibleImage = ({ imageInfo }) => {
  const imageStyle = imageInfo.style ?? { position: "unset" };
  const { alt = "", childImageSharp, image, imgStyle, className } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <Img
        style={imageStyle}
        imgStyle={imgStyle}
        fluid={image.childImageSharp.fluid}
        className={className}
        alt={alt}
        loading="lazy"
        draggable={false}
      />
    );
  }

  if (!!childImageSharp) {
    return (
      <Img
        style={imageStyle}
        imgStyle={imgStyle}
        fluid={childImageSharp.fluid}
        className={className}
        alt={alt}
        loading="lazy"
      />
    );
  }

  if (!!image && typeof image === "string")
    return <img style={imageStyle} src={image} alt={alt} loading="lazy" />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    style: PropTypes.object,
    imgStyle: PropTypes.object,
    className: PropTypes.string,
  }).isRequired,
};

export default PreviewCompatibleImage;
