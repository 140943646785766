import React from "react";

const GradientLine: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = ({ children, className }) => (
  <div className={`container-width-l ${className ?? ":"}`}>
    <div className="gradient-line" />
    {children}
  </div>
);

export default GradientLine;
