import React from "react";
import { Release } from "../../../models";
import { PreviewCompatibleImage, SocialIcons } from "../../index";
import { animCoverProps__logo } from "../../../helpers";
import AniLink from "gatsby-plugin-transition-link/AniLink";

type ReleasesCardProps = {
  release: Release;
  slug: string;
  className?: string;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const ReleaseCard = (props: ReleasesCardProps) => {
  const { author, name, dsp, date, featuredimage } = props.release;
  const songName = () => `${author} - ${name}`;
  return (
    <div  className={`releases__item ${props.className ?? ""}`}>
      <div className="releases__item__wrapper">
        <div className="releases__item__wrapper__content">
          <AniLink cover to={props.slug} {...animCoverProps__logo}>
            <span className="releases__item__wrapper__content__field">
              <h3>{author}</h3>
            </span>
          </AniLink>
          <AniLink cover to={props.slug} {...animCoverProps__logo}>
            <div className="releases__item__wrapper__content__field">
              <h4>{name}</h4>
            </div>
          </AniLink>
          <div className="releases__item__wrapper__content__field">
            <h5>{String(date)}</h5>
          </div>
        </div>
        <SocialIcons
          data={dsp}
          className="releases__item__wrapper__actions"
          iconClass="releases__item__wrapper__actions__social"
        />
      </div>
      <div className="releases__item__thumbnail">
        <PreviewCompatibleImage
          imageInfo={{
            image: featuredimage,
            alt: songName(),
            style: {
              aspectRatio: "1",
            },
          }}
        />
      </div>
    </div>
  );
};

export default ReleaseCard;
