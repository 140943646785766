import React from "react";
import { BaseProps } from "../models";

type HTMLBodyProps = { content: any } & BaseProps;

const HTMLBody: React.FC<HTMLBodyProps> = ({ content, className }) => {
  if (!content) {
    return null;
  } else if (typeof content === "string") {
    return (
      <main
        className={["rc-body", className].filter(Boolean).join(" ")}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  } else {
    return <main className={className}>{content}</main>;
  }
};

export default HTMLBody;
