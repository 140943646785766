import React from "react";
import { Curtains, Plane } from "curtainsjs";
import {
  fragmentShader as slideShowFS,
  vertexShader as slideShowVS,
} from "./shaders";
import { PreviewCompatibleImage } from "..";
import { isMobile } from "../../helpers";
import "./Curtains.scss";

type Props = {
  images: { src: string; alt: string }[];
  displacement?: string;
  imageClass?: string;
  className?: string;
  delay?: number;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const WebGLSlideShow: React.FC<Props> = ({
  images,
  displacement,
  imageClass,
  className,
  delay = 5,
}: Props) => {
  const [slideshowInterval, setSlideShowInterval] = React.useState(null);

  const createSlideShow = () => {
    // set up our WebGL context and append the canvas to our wrapper
    const curtains = new Curtains({
      container: "canvas",
      watchScroll: false, // no need to listen for the scroll in this example
      pixelRatio: Math.min(1.5, window.devicePixelRatio), // limit pixel ratio for performance
    });

    // get our plane element
    const planeElements = document.getElementsByClassName("curtain__slides");

    // here we will handle which texture is visible and the timer to transition between images
    const slideshowState = {
      activeTextureIndex: 1,
      nextTextureIndex: 2, // does not care for now
      maxTextures: planeElements[0]?.querySelectorAll("img")?.length - 1, // -1 because displacement image does not count

      isChanging: false,
      transitionTimer: 0,
    };

    // handling errors
    curtains
      .onError(() => {
        // we will add a class to the document body to display original images
        document.body.classList.add("no-curtains", "image-1");

        // handle simple slides management here
        planeElements[0].addEventListener("click", () => {
          if (slideshowState.activeTextureIndex < slideshowState.maxTextures) {
            slideshowState.nextTextureIndex =
              slideshowState.activeTextureIndex + 1;
          } else {
            slideshowState.nextTextureIndex = 1;
          }

          document.body.classList.remove(
            "image-1",
            "image-2",
            "image-3",
            "image-4"
          );
          document.body.classList.add(
            "image-" + slideshowState.nextTextureIndex
          );

          slideshowState.activeTextureIndex = slideshowState.nextTextureIndex;
        });
      })
      .onContextLost(() => {
        // on context lost, try to restore the context
        curtains.restoreContext();
      });

    // disable drawing for now
    curtains.disableDrawing();

    // some basic parameters
    const params = {
      vertexShader: slideShowVS,
      fragmentShader: slideShowFS,
      uniforms: {
        transitionTimer: {
          name: "uTransitionTimer",
          type: "1f",
          value: 0,
        },
      },
    };
    const multiTexturesPlane = new Plane(curtains, planeElements[0], params);
    let activeTex: any;
    let nextTex: any;

    const onCurtainsReady = () => {
      // the idea here is to create two additionnal textures
      // the first one will contain our visible image
      // the second one will contain our entering (next) image
      // that way we will deal with only activeTex and nextTex samplers in the fragment shader
      // and we could easily add more images in the slideshow...
      // first we set our very first image as the active texture
      activeTex = multiTexturesPlane.createTexture({
        sampler: "activeTex",
        fromTexture:
          multiTexturesPlane.textures[slideshowState.activeTextureIndex],
      });
      // next we set the second image as next texture but this is not mandatory
      // as we will reset the next texture on slide change
      nextTex = multiTexturesPlane.createTexture({
        sampler: "nextTex",
        fromTexture:
          multiTexturesPlane.textures[slideshowState.nextTextureIndex],
      });

      planeElements[0].addEventListener("click", slideChangeEvent);

      const interval = setInterval(() => slideChangeEvent(), delay * 1000);

      setSlideShowInterval(interval);
    };

    const slideChangeEvent = () => {
      if (!slideshowState.isChanging) {
        // enable drawing for now
        curtains.enableDrawing();

        slideshowState.isChanging = true;

        // check what will be next image
        if (slideshowState.activeTextureIndex < slideshowState.maxTextures) {
          slideshowState.nextTextureIndex =
            slideshowState.activeTextureIndex + 1;
        } else {
          slideshowState.nextTextureIndex = 1;
        }
        // apply it to our next texture
        nextTex.setSource(
          multiTexturesPlane.images[slideshowState.nextTextureIndex]
        );

        setTimeout(() => {
          // disable drawing now that the transition is over
          curtains.disableDrawing();

          slideshowState.isChanging = false;
          slideshowState.activeTextureIndex = slideshowState.nextTextureIndex;
          // our next texture becomes our active texture
          activeTex.setSource(
            multiTexturesPlane.images[slideshowState.activeTextureIndex]
          );
          // reset timer
          slideshowState.transitionTimer = 0;
        }, 1700); // add a bit of margin to the timer
      }
    };

    multiTexturesPlane
      .onLoading((texture) => {
        // improve texture rendering on small screens with LINEAR_MIPMAP_NEAREST minFilter
        texture.setMinFilter(curtains.gl.LINEAR_MIPMAP_NEAREST);
      })
      .onReady(() => {
        onCurtainsReady();
      })
      .onRender(() => {
        // increase or decrease our timer based on the active texture value
        if (slideshowState.isChanging) {
          // use damping to smoothen transition
          slideshowState.transitionTimer +=
            (90 - slideshowState.transitionTimer) * 0.04;

          // force end of animation as damping is slower the closer we get from the end value
          if (
            slideshowState.transitionTimer >= 88.5 &&
            slideshowState.transitionTimer !== 90
          ) {
            slideshowState.transitionTimer = 90;
          }
        }

        // update our transition timer uniform
        multiTexturesPlane.uniforms.transitionTimer.value =
          slideshowState.transitionTimer;
      });
  };

  React.useEffect(() => {
    createSlideShow();
    return () => {
      clearInterval(slideshowInterval);
    };
  }, []);

  if (images?.length > 0)
    return (
      <>
        <div id="canvas"></div>
        <div className={`curtain__slides ${className ?? ""}`}>
          <img
            src={displacement ? displacement : "img/displacement.jpg"}
            data-sampler="displacement"
            loading="lazy"
          />
          {images.map(({ src, alt }: any, i) => {
            return (
              <PreviewCompatibleImage
                key={`${alt}-${i}`}
                imageInfo={{
                  image: isMobile ? src.childImageSharp.fluid.src : src,
                  style: {
                    width: "300px",
                  },
                }}
              />
            );
          })}
        </div>
      </>
    );
  else return <></>;
};

export default WebGLSlideShow;
