import React from "react";

const useHideOnScrolled = () => {
  const [hidden, setHidden] = React.useState(false);
  const [scrollPos, setScrollPos] = React.useState(NaN);
  const [prevScrollPos, setPrevScrollPos] = React.useState(NaN);

  React.useEffect(() => {
    setHidden(prevScrollPos < scrollPos && scrollPos != 0);
    setPrevScrollPos(scrollPos);
  }, [scrollPos]);

  React.useEffect(() => {
    const handleScroll = () => setScrollPos(window.pageYOffset);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return hidden;
};

export default useHideOnScrolled;
