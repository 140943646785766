// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artists-index-tsx": () => import("./../../../src/pages/artists/index.tsx" /* webpackChunkName: "component---src-pages-artists-index-tsx" */),
  "component---src-pages-press-index-tsx": () => import("./../../../src/pages/press/index.tsx" /* webpackChunkName: "component---src-pages-press-index-tsx" */),
  "component---src-pages-press-release-index-tsx": () => import("./../../../src/pages/press-release/index.tsx" /* webpackChunkName: "component---src-pages-press-release-index-tsx" */),
  "component---src-pages-releases-index-tsx": () => import("./../../../src/pages/releases/index.tsx" /* webpackChunkName: "component---src-pages-releases-index-tsx" */),
  "component---src-pages-tags-index-tsx": () => import("./../../../src/pages/tags/index.tsx" /* webpackChunkName: "component---src-pages-tags-index-tsx" */),
  "component---src-templates-artist-page-tsx": () => import("./../../../src/templates/artist-page.tsx" /* webpackChunkName: "component---src-templates-artist-page-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-mart-tsx": () => import("./../../../src/templates/mart.tsx" /* webpackChunkName: "component---src-templates-mart-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/press-release.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-press-tsx": () => import("./../../../src/templates/press.tsx" /* webpackChunkName: "component---src-templates-press-tsx" */),
  "component---src-templates-privacy-page-tsx": () => import("./../../../src/templates/privacy-page.tsx" /* webpackChunkName: "component---src-templates-privacy-page-tsx" */),
  "component---src-templates-release-tsx": () => import("./../../../src/templates/release.tsx" /* webpackChunkName: "component---src-templates-release-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

