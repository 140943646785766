import React from "react";
import gsap from "gsap";
import { AppActions, GlobalDispatch, GlobalState } from "../../store";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import {
  animCoverProps__logo,
  GripTapeGrid,
  isCurrentPage,
} from "../../helpers";
import "./Menu.scss";

const Menu: React.FC = () => {
  // prettier-ignore
  const links = [{ text: "Home", path: "/" }, { text: "Creators",path: "/artists" }, { text: "Releases",path: "/releases" }];
  const dispatch = React.useContext(GlobalDispatch);
  const state = React.useContext(GlobalState);
  let grid: GripTapeGrid;

  // Close menu on esc keydown
  const onEscClick = (event) =>
    event.keyCode === 27 && dispatch(AppActions.toggleMenu(false));

  React.useEffect(() => {
    if (state.menuOpen) {
      grid = new GripTapeGrid(gsapElements.menuItems.current);
      document.addEventListener("keydown", onEscClick, false);
      document.body.classList.add("menu-open");
    } else {
      document.body.classList.remove("menu-open");
      grid?.kill();
      document.removeEventListener("keydown", onEscClick, false);
    }
    return () => grid?.kill();
  }, [state.menuOpen]);

  // Animations
  const gsapElements = {
    menuItems: React.useRef([]),
    container: React.useRef(null),
  };
  gsapElements.menuItems.current = [];

  const addToMenuRefs = (el) => {
    if (el && !gsapElements.menuItems.current.includes(el)) {
      gsapElements.menuItems.current.push(el);
    }
  };

  const findMenuItemRef = (id: string) =>
    gsapElements.menuItems.current.find((el) => id === el.dataset.itemId);

  // prettier-ignore
  const fillTextColor = (id: string, out?: boolean): void => {
    const menuLink__anim: Element = findMenuItemRef(id).children[0];
    const menuLink__anim__filledText: Element = menuLink__anim.children[0];
    gsap.fromTo(menuLink__anim, { x: !out ? "-100%" : "0" }, { x: !out ? "0" : "100%" });
    gsap.fromTo(menuLink__anim__filledText, { x: !out ? "100%" : "0" }, { x: !out ? "0" : "-100%" });
  };

  return (
    <div
      className={`menu__fullscreen ${
        state.menuOpen ? "" : "menu__fullscreen--hidden"
      }`}
    >
      <div className="menu__fullscreen__container" ref={gsapElements.container}>
        <ul className="menu__fullscreen__container--links">
          {links.map(({ text, path }, index) => (
            <AniLink
              cover
              to={path}
              {...animCoverProps__logo}
              onMouseEnter={() => fillTextColor(text)}
              onMouseLeave={() => fillTextColor(text, true)}
              onClick={() =>
                isCurrentPage([path, text])
                  ? dispatch(AppActions.toggleMenu(false))
                  : null
              }
              key={index}
            >
              <div
                className="inner-menu-link"
                ref={addToMenuRefs}
                data-item-id={`${text}`}
              >
                <span className="inner-menu-link__anim">
                  <span className="inner-menu-link__anim--filled--text">
                    {text}
                  </span>
                </span>
                <span className="inner-menu-link__outlined--text">{text}</span>
              </div>
            </AniLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Menu;
