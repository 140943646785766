import React from "react";
import { useCookies } from "react-cookie";
import { graphql, StaticQuery } from "gatsby";
import { Icon, Newsletter } from "../index";
import {
  isCookieEnabled,
  POPUP__COOKIE,
  PREFERENCES__COOKIE,
} from "../../helpers";
import "./Newsletter.scss";

type Props = {
  data?: {
    title?: string;
    description?: string;
    timeout: number;
    expiration: number;
  };
  forceVisibility?: boolean;
  style?: React.StyleHTMLAttributes<any>;
} & React.HTMLAttributes<HTMLDivElement>;

const NewsletterPopup: React.FC<Props> = ({
  data: { title, description, timeout, expiration },
  forceVisibility,
  style,
}: Props) => {
  const [showen, setShowen] = React.useState(false);
  const [cookies, setCookie] = useCookies([POPUP__COOKIE]);

  React.useEffect(() => {
    // If cookie does not exist, wait few seconds and pop up
    if (isCookieEnabled(POPUP__COOKIE) === undefined)
      setTimeout(() => setShowen(true), timeout * 1000);
  }, [cookies]);

  const close = () => {
    // Set expiration date to cookie
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + expiration);

    // Set cookie to off for limited time
    isCookieEnabled(PREFERENCES__COOKIE) &&
      setCookie(POPUP__COOKIE, true, { expires: expireDate });

    setShowen(false);
  };

  if (showen || forceVisibility)
    return (
      <div className="newsletter__popup" style={style}>
        <div className="newsletter__popup__info">
          <div className="newsletter__popup__info--header">
            <div className="newsletter__popup__info--header--title">
              <h5>{title}</h5>
            </div>
            <Icon name="close" onClick={() => close()} />
          </div>
          <p className="newsletter__popup__info--description">{description}</p>
        </div>
        <Newsletter />
      </div>
    );
  else return null;
};

export const NewsletterPopupTemplate = ({ data }) => (
  // Adjusting distance from bottom for cms preview, also forcing visibillity
  <NewsletterPopup data={data} style={{ bottom: "50%" }} forceVisibility />
);

export default ({ data }: Props) => (
  <StaticQuery
    query={graphql`
      query NewsletterPopupData {
        markdownRemark(frontmatter: { templateKey: { eq: "popup" } }) {
          frontmatter {
            title
            description
            timeout
            expiration
          }
        }
      }
    `}
    render={(data) => (
      <NewsletterPopup data={data?.markdownRemark?.frontmatter} />
    )}
  />
);
