import React from "react";
import { SocialBar, Menu, NewsletterPopup } from ".";

const StaticLayout = ({ children }) => (
  <React.Fragment>
    <NewsletterPopup />
    <SocialBar />
    <Menu />
    {children}
  </React.Fragment>
);
export default StaticLayout;
