import React from "react";
import { Icon } from "..";
import { Social } from "../../models";

type Props = {
  data: Social;
  size?: number;
  className?: string;
  iconClass?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const SocialIcons: React.FC<Props> = ({
  data,
  size = 40,
  className,
  style,
  iconClass,
}: Props) => {
  const [isReady, setIsReady] = React.useState(false);
  const [readyIcons, setReadyIcons] = React.useState([]);

  React.useEffect(() => {
    if (readyIcons.length === data?.links?.length && !isReady) setIsReady(true);
  }, [readyIcons]);

  return data?.links?.length ? (
    <div
      className={`${className ?? "page__footer__social"} social__icons`}
      style={{ visibility: !isReady ? "hidden" : "visible", ...style }}
    >
      {data.links.map(({ type, url }) => (
        <a href={url} key={type} target="blank" className={iconClass ?? "icon"}>
          <Icon
            name={["Listen", "Pre Save"].includes(type) ? "url" : type}
            size={size}
            onReady={() => setReadyIcons((icons) => [...icons, type])}
          />
        </a>
      ))}
    </div>
  ) : null;
};

export default SocialIcons;
