import { AppTypes } from "./index";

const toggleMenu = (payload?: boolean) => ({
  type: AppTypes.TOGGLE_MENU,
  payload,
});

const toggleIgnoreFooter = (payload?: boolean) => ({
  type: AppTypes.TOGGLE_IGNORE_FOOTER,
  payload,
});

const addVisitedPage = (payload?: string) => ({
  type: AppTypes.ADD_VISITED_PAGE,
  payload,
});

export default {
  toggleMenu,
  toggleIgnoreFooter,
  addVisitedPage,
};
