import React, { forwardRef, useState } from "react";
import Tilt from "react-parallax-tilt";
import { RealCvltvreIcon } from "../../SVG";
import { isMobile } from "../../../helpers";
import "./Intro.scss";

type Props = {
  data: { heading: string; description: string };
  children?: React.ReactNode;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const IntroSection = forwardRef((props: Props, ref: any) => {
  const [logoHover, setLogoHover] = useState(false);
  return (
    <header className="section intro" ref={ref} id="home">
      <main
        className={`intro__greeter ${logoHover ? "intro__greeter--hover" : ""}`}
      >
        <p>{props.data.description}</p>
      </main>
      {isMobile ? (
        <div className="intro__logo">
          <RealCvltvreIcon />
        </div>
      ) : (
        <Tilt
          perspective={200}
          className="intro__logo"
          onEnter={() => setLogoHover(true)}
          onLeave={() => setLogoHover(false)}
        >
          <RealCvltvreIcon />
        </Tilt>
      )}
      {props.children}
    </header>
  );
});

export default IntroSection;
