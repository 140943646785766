import React, { useState } from "react";
import { TimelineLite, Expo } from "gsap";
import { rcEncodeUri } from "../../../../helpers/utils";
import "./Contact.scss";

type Props = {
  className?: string;
  requirements?: any;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const Contact = (props: Props) => {
  const [contact, setContact] = useState(null);
  const [completed, setCompleted] = React.useState(false);
  const [formError, setFormError] = React.useState(false);

  // ---------------------- // GSAP
  const tl = new TimelineLite();
  const gsapEl = { rollup: React.useRef(null), thankU: React.useRef(null) };
  const revealThankU = () => {
    const { rollup, thankU } = gsapEl;
    const timing = { duration: 1.66, ease: Expo.easeInOut };
    tl.to(rollup.current, { y: "0", ...timing })
      .to(thankU.current, { y: "-100%", ...timing }, ">")
      .to(rollup.current, { y: "-100%", autoAlpha: 0, ...timing }, "<");
    tl.play();
  };

  React.useEffect(() => {
    if (completed) revealThankU();
    return () => {
      tl.clear();
    };
  }, [completed]);
  // ---------------------- // FORM
  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!contact || !contact.name || !contact.email) return;
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: rcEncodeUri({
        "form-name": form.getAttribute("name"),
        ...contact,
      }),
    })
      .then(({ ok }) => !ok && setFormError(true))
      .finally(() => setCompleted(true));
  };

  return (
    <section className={`contact ${props.className ?? ""}`}>
      <form
        className="contact__form"
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />

        <div hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={handleChange} />
          </label>
        </div>

        <div className="contact__field">
          <input
            id={"name"}
            type={"text"}
            name={"name"}
            maxLength={50}
            required={true}
            className="input"
            placeholder="Your name"
            onChange={handleChange}
            autoComplete={"name"}
          />
        </div>
        <div className="contact__field">
          <input
            id={"contact-email"}
            type={"email"}
            name={"email"}
            maxLength={80}
            required={true}
            className="input"
            placeholder="Email address"
            onChange={handleChange}
            autoComplete={"email"}
          />
        </div>
        {props.requirements && (
          <div className="contact__field contact__field--select">
            <select
              id={"requirments"}
              name={"requirments"}
              required={true}
              className="input"
              onChange={handleChange}
              defaultValue={""}
            >
              <option disabled value={""}>
                Topic
              </option>
              {props.requirements?.map(({ reason }, index) => (
                <option value={reason} key={index}>
                  {reason}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="contact__field">
          <textarea
            id={"message"}
            name={"message"}
            className="textarea"
            onChange={handleChange}
            placeholder="Write something"
            required={true}
          />
        </div>

        <div className="contact__footer">
          <button type="submit">
            <span>Send</span>
          </button>
        </div>
      </form>
      {completed && (
        <div className="contact__thank-u">
          <div className="contact--rollup" ref={gsapEl.rollup}></div>
          <div className="contact--confirmation" ref={gsapEl.thankU}>
            {formError ? (
              <>
                <h1>Oops,</h1>
                <h5>try again later</h5>
              </>
            ) : (
              <>
                <h5>We'll get back to you</h5>
                <h1>ASAP</h1>
              </>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
