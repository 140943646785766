export const TOGGLE_MENU = "[MENU] Toggle Menu";
export const TOGGLE_IGNORE_NAV = "[MENU] Toggle Ignore Nav";
export const TOGGLE_IGNORE_FOOTER = "[MENU] Toggle Ignore Footer";
export const ADD_VISITED_PAGE = "[Press] Add Visited Page";

export default {
  TOGGLE_MENU,
  TOGGLE_IGNORE_NAV,
  TOGGLE_IGNORE_FOOTER,
  ADD_VISITED_PAGE,
};
