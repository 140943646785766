import React from "react";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import ReleaseCard from "./ReleaseCard";
import "./Releases.scss";

const ReleasesRoll = ({ data }) => {
  const { edges: releases } = data.allMarkdownRemark;

  return (
    <div className="releases gsap-list">
      {releases &&
        releases.map(({ node: release }) => (
          <ReleaseCard
            release={release.frontmatter}
            slug={release.fields.slug}
            key={release.id}
          />
        ))}
    </div>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query ReleasesRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "release" } } }
          limit: 8
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                name
                author
                date(formatString: "YYYY")
                templateKey
                dsp {
                  links {
                    type
                    url
                  }
                }
                featuredimage {
                  childImageSharp {
                    fluid(
                      maxWidth: 200
                      quality: 100
                      srcSetBreakpoints: [100, 200]
                    ) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <ReleasesRoll data={data} />}
  />
);
