import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata: any = () => {
  const {
    site,
    intro: {
      frontmatter: { intro },
    },
  } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
        intro: markdownRemark(
          frontmatter: { templateKey: { eq: "index-page" } }
        ) {
          frontmatter {
            intro {
              description
            }
          }
        }
      }
    `
  );
  return { ...site.siteMetadata, intro };
};

export default useSiteMetadata;
