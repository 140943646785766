import React, { useEffect, useContext } from "react";
import { AppActions, GlobalState, GlobalDispatch } from "../store";

const closeMenuOnLoad = () => {
  const state = useContext(GlobalState);
  const dispatch = useContext(GlobalDispatch);
  useEffect(() => {
    if (state.menuOpen) dispatch(AppActions.toggleMenu(false));
  }, []);
};

export default closeMenuOnLoad;
