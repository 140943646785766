import React from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";

export class FadeOnScroll {
  public elements: any[] = [];
  private scrollTriggers: gsap.plugins.ScrollTriggerInstance[] = [];

  constructor(private selector: string) {
    this.elements = gsap.utils?.toArray(this.selector);

    gsap.registerPlugin(ScrollTrigger);
    this.createScrollFades();
  }

  /** Actual animation on scroll */
  private fadeUp = (target: any) =>
    gsap.fromTo(
      target,
      { autoAlpha: 0, y: 50 },
      { duration: 1, autoAlpha: 1, y: 0 }
    );

  /** Initialize scrolling fade */
  private createScrollFades(): void {
    for (const el of this.elements) {
      const st = ScrollTrigger.create({
        trigger: el,
        animation: this.fadeUp(el),
        toggleActions: "play none none none",
        once: true,
      });
      this.scrollTriggers.push(st);
    }
  }

  /** Kills all scrollTrigger instances */
  public kill(): void {
    this.scrollTriggers.forEach((st) => st.kill());
  }
}
/**
 * Fade items once they are available on scroll
 * @param selector - querySelector for all the elements you want to fade
 */
export const useFadeOnScroll = (selector: string) => {
  React.useEffect(() => {
    const fon = new FadeOnScroll(selector);
    return () => {
      fon.kill();
    };
  }, []);
};
