import React from "react";
import { ProgressNavbar } from "../ProgressNavbar";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { animCoverProps__logo } from "../../helpers";
import { RealCvltvreIcon } from "../SVG";

const FixedMenuNav: React.FC = () => {
  return (
    <ProgressNavbar>
      <AniLink
        cover
        to={"/"}
        className="white-svg full"
        {...animCoverProps__logo}
      >
        <RealCvltvreIcon className="minus90ondesktop" />
      </AniLink>
    </ProgressNavbar>
  );
};

export default FixedMenuNav;
