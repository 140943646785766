import React from "react";
import { gsap } from "gsap";
import { lerp, getMousePos, isMobile } from "../utils";
export class Cursor {
  public bounds;
  public renderedStyles;
  public mouse = { x: 0, y: 0 };
  public element;
  public frames: number[] = [];

  constructor(public el: HTMLElement, public triggers?: HTMLElement[]) {
    this.el.style.opacity = String(0);
    this.bounds = this.el.getBoundingClientRect();

    this.renderedStyles = {
      tx: { previous: 0, current: 0, amt: 0.2 },
      ty: { previous: 0, current: 0, amt: 0.2 },
      scale: { previous: 1, current: 1, amt: 0.2 },
      opacity: { previous: 1, current: 1, amt: 0.2 },
    };

    this.prepareTriggers();
    window.addEventListener(
      "mousemove",
      (ev) => (this.mouse = getMousePos(ev))
    );
    window.addEventListener("mousemove", this.onMouseMoveEv);
  }

  render() {
    this.renderedStyles["tx"].current = this.mouse.x - this.bounds.width / 2;
    this.renderedStyles["ty"].current = this.mouse.y - this.bounds.height / 2;

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(
        this.renderedStyles[key].previous,
        this.renderedStyles[key].current,
        this.renderedStyles[key].amt
      );
    }

    this.el.style.transform = `translateX(${this.renderedStyles["tx"].previous}px) translateY(${this.renderedStyles["ty"].previous}px) scale(${this.renderedStyles["scale"].previous})`;
    // this.el.style.opacity = this.renderedStyles['opacity'].previous;

    this.frames.push(requestAnimationFrame(() => this.render()));
  }

  onMouseMoveEv = () => {
    this.renderedStyles.tx.previous = this.renderedStyles.tx.current =
      this.mouse.x - this.bounds.width / 2;
    this.renderedStyles.ty.previous = this.renderedStyles.ty.previous =
      this.mouse.y - this.bounds.height / 2;
    gsap.to(this.el, { duration: 1.9, ease: "Power3.easeOut", opacity: 1 });
    this.frames.push(requestAnimationFrame(() => this.render()));
    window.removeEventListener("mousemove", this.onMouseMoveEv);
  };

  enter() {
    this.el.classList.add("cursor-enter");
    this.renderedStyles["scale"].current = 7;
    // this.renderedStyles['opacity'].current = 0.2;
  }

  leave() {
    this.el.classList.remove("cursor-enter");
    this.renderedStyles["scale"].current = 1;
    // this.renderedStyles['opacity'].current = 1;
  }

  private prepareTriggers() {
    for (const el of this.triggers) {
      el.addEventListener("mouseenter", () => this.enter());
      el.addEventListener("mouseleave", () => this.leave());
    }
  }

  public kill() {
    if (this.frames.length > 1)
      for (const id of this.frames) cancelAnimationFrame(id);
  }
}
// TODO: document it
export const useCursor = (selector: string) => {
  React.useEffect(() => {
    if (isMobile)
      return () => {
        null;
      };
    const cursorEl = document.querySelector(selector) as HTMLElement;
    const triggers = Array.from(
      document.querySelectorAll("button, a, svg, .cursor-trigger")
    ) as HTMLElement[];
    const cursor = new Cursor(cursorEl, triggers);
    return () => {
      cursor.kill();
    };
  }, []);
};
