import { Reducer } from "react";
import { addPageToSession, fixPath, getPageSession } from "../components/Press/PressPassHelper";
import { AppStateModel, AppTypes } from "./index";



export const initialState: AppStateModel = {
  menuOpen: false,
  visitedPages: getPageSession() ?? [],
};

export const appReducer: Reducer<AppStateModel, any> = (state, action) => {
  switch (action.type) {
    case AppTypes.TOGGLE_MENU: {
      return {
        ...state,
        menuOpen: action.payload ?? !state.menuOpen,
      };
    }

    case AppTypes.TOGGLE_IGNORE_FOOTER: {
      return {
        ...state,
        ignoreFooter: action.payload ?? !state.ignoreFooter,
      };
    }

    case AppTypes.ADD_VISITED_PAGE: {
      addPageToSession(action.payload)
      return {
        ...state,
        visitedPages: [...state.visitedPages, fixPath(action.payload)]
      };
    }

    default:
      return state;
  }
};
