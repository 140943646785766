import { TimelineLite, Expo } from "gsap";

export const pageIntroFadeAnimation = () => {
  const tl = new TimelineLite();
  const easing = { ease: Expo.easeInOut };
  const timing = { duration: 1, ...easing };
  return tl
    .from(
      ".section__header__container--title",
      { y: 200, delay: 0.2, ...timing },
      ">"
    )
    .from(".page__subtitle", { yPercent: -120, ...timing }, "<")
    .from("main", { y: 50, opacity: 0, duration: 3, ...easing }, "<");
};

// AniLink REAL CVLTVRE Pre-built Transitions
export const animCoverProps__logo = {
  duration: 1.2,
  direction: "up",
  bg:
    "url(/img/icon-white.png) center / 150px no-repeat fixed padding-box content-box black",
};

export const animCoverProps__firemoji = {
  duration: 1,
  direction: "up",
  bg: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><style>@keyframes foo %7Bto %7b transform: rotate(-10deg)}}</style><text y="1em" font-size="80" style="animation: foo 260ms infinite alternate">🔥</text></svg>') center / 100px  fixed black repeat`,
};
